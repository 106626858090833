import React, { useEffect, useState } from "react";
import cn from "classnames";
import CurrencyComponent from "../CurrencyComponent/CurrencyComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateGet,
  fetchCryptoCurrencies,
  fetchFiatCurrencies,
  searchCurrencies,
  searchFiatCurrencies,
  selectCryptoCurrencies,
  selecteSearched,
  selectFiat,
  selectLoading,
  selectMin,
  selectMode,
  selectOffset,
  setActiveFrom,
  setActiveTo,
  setOffset,
  setSearchVal,
} from "../../features/crypto/cryptoSlice";
import styles from "./SelectList.module.scss";
import { useDebounce, useIntersection } from "react-use";

const SelectList = ({ setActive, isFrom }) => {
  const cryptoList = useSelector(selectCryptoCurrencies);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold: 0.4,
  });
  const offset = useSelector(selectOffset);
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const [search, setSearch] = useState("");
  const searchedItems = useSelector(selecteSearched);
  const mode = useSelector(selectMode);
  const fiatList = useSelector(selectFiat);

  const handleChange = (e) => {
    setSearch(e.target.value);
    dispatch(setSearchVal(e.target.value));
  };

  const handleFetch = () => {
    if (mode.id === 2 && isFrom) {
      dispatch(searchFiatCurrencies());
    } else {
      dispatch(searchCurrencies());
    }
  };

  useDebounce(handleFetch, 300, [search]);

  const handleSet = (crypto) => {
    console.log(crypto);
    if (isFrom) {
      dispatch(setActiveFrom(crypto));
      setActive(false);
      console.log("From");
    } else {
      dispatch(setActiveTo(crypto));
      setActive(false);
      console.log("To");
    }
    dispatch(calculateGet());
  };

  useEffect(() => {
    if (intersection?.isIntersecting) {
      if (!loading) {
        console.log("Intersecting");
        dispatch(setOffset(offset + 30));
        dispatch(fetchCryptoCurrencies());
        // dispatch(fetchFiatCurrencies());
      }
    }
  }, [intersection?.isIntersecting]);

  if (!cryptoList.length) return;

  return (
    <div className={cn("fixed inset-0 flex justify-center z-[100]")}>
      <div className="fixed inset-0 z-[100] opacity-90 bg-static-dark" />
      <div className="absolute z-[100] sm:bottom-[50vh] bottom-0 sm:translate-y-[50%] w-full sm:w-auto top-0 bg-background-secondary sm:top-auto sm:bg-transparent">
        <div className="w-full h-full sm:w-[500px] sm:h-[640px] sm:rounded-l overflow-hidden">
          <div
            className="flex flex-col bg-sexy-black-0 rounded-l w-full h-full"
            data-tnav="widget-currency-list"
          >
            <header className="flex items-center justify-between w-full px-4 py-3 gap-4">
              <button
                className="w-10 h-10 flex items-center justify-center text-sexy-black-500 bg-transparent rounded-s cursor-pointer hover:bg-sexy-black-100"
                data-tnav="widget-popup-card-btn-back"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m11.3 4.3-7 7c-.1.1-.2.2-.2.3-.1.2-.1.5 0 .8.1.1.1.2.2.3l7 7c.4.4 1 .4 1.4 0s.4-1 0-1.4L7.4 13H19c.6 0 1-.4 1-1s-.4-1-1-1H7.4l5.3-5.3c.2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.4-.4-1-.4-1.4 0"
                  />
                </svg>
              </button>
              <p
                className="text-h5 flex-grow font-semi-bold"
                data-tnav="widget-popup-card-title"
              >
                Select a currency
              </p>
              <button
                onClick={() => setActive(false)}
                className="w-10 h-10 flex items-center justify-center text-sexy-black-500 bg-transparent rounded-s cursor-pointer hover:bg-sexy-black-100"
                data-tnav="widget-popup-card-btn-close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m13.4 12 5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L12 10.6 6.7 5.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z"
                  />
                </svg>
              </button>
            </header>
            <div className="CurrencyList_wrapper__Yh1xf">
              <div>
                <header className="flex flex-col p-4 pt-0 space-y-4 border-b border-sexy-black-300">
                  <section>
                    <div className="relative">
                      <input
                        data-tnav="currency-list-search-bar"
                        id="search"
                        placeholder="Search currency"
                        className="w-full pl-3 outline-none bg-control-input-default placeholder-content-secondary text-content-primary border rounded-s hover:bg-control-input-hover hover:text-content-primary hover:border-border-secondary hover:placeholder-content-primary hover:color-content-primary focus:border-border-secondary focus:bg-sexy-black-0 focus:placeholder-content-primary h-10 p2 border-transparent pr-9 Input_input__3O7a3"
                        type="search"
                        name="search"
                        value={search}
                        onChange={handleChange}
                      />
                      <div className="h-0.5 rounded-b-s absolute bottom-0 left-1/2 -translate-x-1/2 transition-all delay-75 w-0 bg-content-primary bg-content-primary" />
                      <div className="absolute right-3 top-1/2 -translate-y-1/2 flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="none"
                          className="cursor-pointer"
                        >
                          <path
                            fill="#B4B6B8"
                            fillRule="evenodd"
                            d="M5 11c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6m15.707 8.293-3.395-3.396A7.95 7.95 0 0 0 19 11c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8a7.95 7.95 0 0 0 4.897-1.688l3.396 3.395a.997.997 0 0 0 1.414 0 1 1 0 0 0 0-1.414"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </section>
                  <div className="flex flex-wrap">
                    <button
                      className="Chip_root__a11dJ inline-flex rounded-xl items-center Chip_primary__vtreJ Chip_selected__BZ7Q_"
                      data-tnav="currency-list-tag"
                    >
                      <span className="text-p2 text-sexy-black-900 font-regular">
                        All
                      </span>
                    </button>
                    <button
                      className="Chip_root__a11dJ inline-flex rounded-xl items-center Chip_primary__vtreJ"
                      data-tnav="currency-list-tag"
                    >
                      <span className="text-p2 text-sexy-black-900 font-regular">
                        Stables
                      </span>
                    </button>
                    <button
                      className="Chip_root__a11dJ inline-flex rounded-xl items-center Chip_primary__vtreJ"
                      data-tnav="currency-list-tag"
                    >
                      <span className="text-p2 text-sexy-black-900 font-regular">
                        Gainers
                      </span>
                    </button>
                    <button
                      className="Chip_root__a11dJ inline-flex rounded-xl items-center Chip_primary__vtreJ"
                      data-tnav="currency-list-tag"
                    >
                      <span className="text-p2 text-sexy-black-900 font-regular">
                        Losers
                      </span>
                    </button>
                    <button
                      className="Chip_root__a11dJ inline-flex rounded-xl items-center Chip_primary__vtreJ"
                      data-tnav="currency-list-tag"
                    >
                      <span className="text-p2 text-sexy-black-900 font-regular">
                        New
                      </span>
                    </button>
                  </div>
                </header>
              </div>
              <div>
                <div className={styles.list}>
                  {search ? (
                    !searchedItems.length ? (
                      <div
                        data-tnav="currency-dropdown-emptystate"
                        className="flex flex-col align-middle justify-center text-center grow-0 p-4"
                        style={{ height: 519 }}
                      >
                        <p className="text-h1 mb-2">👀</p>
                        <p className="text-p2">
                          Sorry, we did not find anything
                        </p>
                      </div>
                    ) : (
                      searchedItems.map((crypto, idx) => (
                        <button
                          key={crypto.id}
                          onClick={(_) => handleSet(crypto)}
                        >
                          <CurrencyComponent
                            ticker={crypto.cmcTicker}
                            name={crypto.name}
                            imageUrl={crypto.img}
                            network={crypto.network}
                          />
                        </button>
                      ))
                    )
                  ) : mode.id === 2 && isFrom ? (
                    fiatList.map((crypto, idx) => (
                      <button
                        key={crypto.id}
                        onClick={(_) => handleSet(crypto)}
                      >
                        <CurrencyComponent
                          ticker={crypto.cmcTicker}
                          name={crypto.name}
                          imageUrl={crypto.img}
                          network={crypto.network}
                        />
                      </button>
                    ))
                  ) : (
                    cryptoList.map((crypto, idx) => (
                      <button
                        key={crypto.id}
                        onClick={(_) => handleSet(crypto)}
                      >
                        <CurrencyComponent
                          ticker={crypto.cmcTicker}
                          name={crypto.name}
                          imageUrl={crypto.img}
                          network={crypto.network}
                        />
                      </button>
                    ))
                  )}
                  <div className={styles.loader} ref={intersectionRef}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectList;
