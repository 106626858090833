import React, { useEffect, useRef, useState } from "react";
import SelectList from "../SelectList/SelectList";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateGet,
  selectGet,
  selectLoading,
  selectMin,
  selectSend,
  setSend,
} from "../../features/crypto/cryptoSlice";
import { useDebounce } from "react-use";

const ExchangeButton = ({ cmcTicker, network, img, name, isFrom }) => {
  const [activePopup, setActivePopup] = useState(false);
  const inputRef = useRef(null);
  // const [sendVal, setSendVal] = useState("0.1");
  const send = useSelector(selectSend);
  const getVal = useSelector(selectGet);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const min = useSelector(selectMin);
  const minRef = useRef(null);

  const handleChange = async (e) => {
    if (isFrom) {
      // setSendVal(e.target.value);
      dispatch(setSend(e.target.value));
    }
  };

  const handleFetch = async (e) => {
    if (isFrom) {
      dispatch(calculateGet());
    }
  };

  const handleSetCurrency = (e) => {
    if (e.target === inputRef.current) return;
    if (e.target === minRef.current) return;
    setActivePopup(true);
  };

  useDebounce(handleFetch, 300, [send]);

  if (!cmcTicker) return;

  return (
    <>
      <div className="CurrencyAmountInput_wrapper__kwitT CurrencyAmountInput_focused__N3NjQ">
        <span className="CurrencyAmountInput_border__RxcsU" />
        <div
          className="CurrencyAmountInput_root__AWGGC relative p-4 rounded-m bg-sexy-black-100"
          data-tnav="input-amount-from"
        >
          <p
            className="text-sexy-black-600 text-p3 mb-2"
            data-tnav="input-amount-main-label"
          >
            {isFrom ? "You send" : "You get"}
          </p>
          <div className="CurrencyAmountInput_input-with-logo__QvuLd">
            <img
              onClick={handleSetCurrency}
              src={img}
              alt="btc logo"
              className="Currency_ticker-img__2oG16 CurrencyAmountInput_logo__pBZlY"
            />
            <div className="CurrencyAmountInput_currency-input-wrp__1YntN">
              <div className="CurrencyAmountInput_ticker-amount-wrp___Ekt9">
                <div
                  onClick={handleSetCurrency}
                  className="Currency_pointer__Ic8Fb CurrencyAmountInput_ticker__PFa2P flex items-center"
                  data-tnav="currency-ticker"
                >
                  <p className="CurrencyAmountInput_ticker-text__Sr2s5 calculator-ticker overflow-ellipsis whitespace-normal">
                    {cmcTicker.toUpperCase()}
                  </p>
                  <i className="inline-flex items-center justify-center ml-1 text-sexy-black-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        d="M12.138 5.862a.667.667 0 0 0-.943 0L8 9.057 4.805 5.862a.667.667 0 1 0-.943.943l3.667 3.666a.667.667 0 0 0 .942 0l3.667-3.666a.667.667 0 0 0 0-.943Z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </i>
                </div>
                {isFrom ? (
                  <input
                    ref={inputRef}
                    placeholder={isFrom ? "You send" : "You get"}
                    className="CurrencyAmountInput_amount__VfyW3 CurrencyAmountInput_ticker-text__Sr2s5 cursor-text text-sexy-black-900"
                    type="text"
                    value={send}
                    onChange={handleChange}
                  />
                ) : !isLoading ? (
                  <span className="getValasas">
                    {getVal ? `~ ${getVal}` : "You get"}
                  </span>
                ) : (
                  <span
                    className="Skeleton_root__ROe0A Skeleton_rectangle__9yEhF"
                    style={{ width: 116, minWidth: 116, height: 24 }}
                  />
                )}

                {/* {!isFrom && (
                  <span
                    className="Skeleton_root__ROe0A Skeleton_rectangle__9yEhF"
                    style={{ width: 116, minWidth: 116, height: 24 }}
                  />
                )} */}
              </div>
              <div
                className="CurrencyAmountInput_label-wrp__2lfJi"
              >
                <div className="flex items-center w-full overflow-hidden">
                  <p
                    className="text-p3 overflow-hidden overflow-ellipsis whitespace-normal"
                    data-tnav="currency-fullname"
                  >
                    {name}
                  </p>
                  <span
                    data-tnav="currency-protocol"
                    className="ml-2 Tag_theme__dAV3O Tag_orange__pI234 inline-flex text-nowrap text-p3 font-semi-bold px-1 rounded-xs"
                  >
                    {network ? network.toUpperCase() : cmcTicker.toUpperCase()}
                  </span>
                </div>
                {isFrom && Number(min) > Number(send) && (
                  <p
                    data-tnav="input-amount-label-error"
                    className="font-semi-bold text-bloodrain-red-600 text-p3 text-right grow shrink whitespace-nowrap cursor-pointer"
                  >
                    Min is&nbsp;
                    <span
                      ref={minRef}
                      onClick={() => {
                        setSend(min);
                        dispatch(setSend(min));
                      }}
                      className="underline"
                    >
                      {min}
                      <span className="CurrencyAmountInput_mobile-hidden__5oXxR">
                        &nbsp;{cmcTicker.toUpperCase()}
                      </span>
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div />
      </div>
      {activePopup && <SelectList isFrom={isFrom} setActive={setActivePopup} />}
    </>
  );
};

export default ExchangeButton;
