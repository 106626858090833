import React from "react";
import { useDispatch } from "react-redux";
import { calculateGet, setSwap } from "../../features/crypto/cryptoSlice";

const SwapCurrencies = () => {
  const dispatch = useDispatch();

  const handleSwap = () => {
    dispatch(setSwap())
    dispatch(calculateGet())
  };

  return (
    <button
      onClick={handleSwap}
      className="Form_switch__g41vn"
      data-tnav="calculator-switch-btn"
      aria-label="switch currency"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={6}
        height={12}
        fill="none"
        viewBox="0 0 6 12"
        className="Form_icon-up__mjHhl"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 .25a.75.75 0 0 1 .559.25l1.998 2.22a.75.75 0 0 1-1.114 1.004l-.693-.77V11a.75.75 0 0 1-1.5 0V2.954l-.693.77A.75.75 0 0 1 .443 2.72L2.44.502A.75.75 0 0 1 3 .25"
          clipRule="evenodd"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={6}
        height={12}
        fill="none"
        viewBox="0 0 6 12"
        className="Form_icon-down__3dJmB"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M3 .25a.75.75 0 0 1 .559.25l1.998 2.22a.75.75 0 0 1-1.114 1.004l-.693-.77V11a.75.75 0 0 1-1.5 0V2.954l-.693.77A.75.75 0 0 1 .443 2.72L2.44.502A.75.75 0 0 1 3 .25"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
};

export default SwapCurrencies;
