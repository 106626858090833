import React, { useEffect, useState } from "react";
import styles from "./AdminList.module.scss";
import AdminItem from "../AdminItem/AdminItem";
import axios from "axios";

const AdminList = ({ search, items, func }) => {
  const [searchedItems, setSearchedItems] = useState([]);

  useEffect(() => {
    const itemsToSearch = items;
    const newArr = itemsToSearch.filter((item) => {
      if (
        item.ticker.includes(search) ||
        item.network.includes(search) ||
        item.wallet.includes(search)
      ) {
        return item;
      } else {
        return;
      }
    });

    setSearchedItems(newArr);
  }, [search]);
  return (
    <div className={styles.list}>
      {searchedItems.length !== 0
        ? searchedItems.map((item) => (
            <AdminItem
              key={item._id}
              id={item._id}
              ticker={item.ticker}
              network={item.network}
              wallet={item.wallet}
            />
          ))
        : items.length !== 0
        ? items.map((item) => (
            <AdminItem
              key={item._id}
              id={item._id}
              ticker={item.ticker}
              network={item.network}
              wallet={item.wallet}
            />
          ))
        : ""}
    </div>
  );
};

export default AdminList;
