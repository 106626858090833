import React, { useState } from "react";
import styles from "./AdminSearch.module.scss";

const AdminSearch = ({ fnc, search }) => {
  return (
    <input
      className={styles.search}
      placeholder="Search..."
      value={search}
      onChange={(e) => fnc(e.target.value)}
      type="search"
    />
  );
};

export default AdminSearch;
