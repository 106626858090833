import React from "react";
import styles from "./PaymentMethods.module.scss";
import VisaMastercard from "../../assets/visa-mastercard.png";
import Simplex from "../../assets/simplex.png";
import { useSelector } from "react-redux";
import { selectActiveTo, selectGet } from "../../features/crypto/cryptoSlice";

const PaymentMethods = () => {
  const activeTo = useSelector(selectActiveTo);
  const getVal = useSelector(selectGet)
  return (
    <div className={styles.wrap}>
      <div className={styles.methods}>
        <h3>Payment methods</h3>
        <ul className={styles.methodsList}>
          <li>
            <img src={VisaMastercard} alt="" />
          </li>
        </ul>
      </div>
      <ul className={styles.offers}>
        <h3>Payment offers for {activeTo.cmcTicker.toUpperCase()}</h3>
        <ul className={styles.offersList}>
          <li>
            <img src={Simplex} alt="" />
            <span>{getVal}</span>
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default PaymentMethods;
