import React from "react";
import { howToSteps } from "../../data/how-to-exchange";

const HowToExchange = () => {
  return (
    <div className="w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4">
      <h3
        className="font-bold text-h3 lg:text-h3-max"
        data-tnav="how-to-section-title"
      >
        How to exchange cryptocurrency
      </h3>
      <div className="pt-8 grid lg:grid-cols-4 sm:gap-4 sm:grid-cols-2">
        {howToSteps.map((step) => (
          <div className="pb-4" key={step.number}>
            <h2 className="pb-2 font-bold text-h1 text-content-accent">
              {step.number}
            </h2>
            <h3 className="font-bold text-h5" data-tnav="how-to-card-title">
              {step.title}
            </h3>
            <p className="text-p2" data-tnav="how-to-card-description">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToExchange;
