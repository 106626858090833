import React from "react";
import styles from "./QrForPay.module.scss";
import QRCode from "react-qr-code";


const QrForPay = ({setActive, value}) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <h3>Changelly address</h3>
          <button onClick={() => setActive(false)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 2L2 14"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M2 2L14 14"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
        </div>
        <div className={styles.qr}>
          <div className={styles.qrInner}>
          <QRCode
            size={180}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={`0 0 180 180`}
            level="H"
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrForPay;
