import React from "react";
import TextAnimation from "../TextAnimation/TextAnimation";

const AdBlock = () => {
  return (
    <div className="flex items-center flex-col mx-4">
      <div className="flex flex-col items-center m-0 text-center  text-h2 sm:text-[38px] sm:leading-[44px] lg:text-[46px] lg:leading-[52px] font-bold text-content-primary">
        <h1 data-tnav="title-section-title">Exchange any crypto</h1>
        <TextAnimation />
      </div>
      <p
        data-tnav="title-section-description"
        className="text-p1 font-regular text-center text-content-secondary mt-3"
      >
        Exchange Bitcoin and 500+ altcoins in a fast, simple, and secure way.
      </p>
    </div>
  );
};

export default AdBlock;
