import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({children, href, isDisabled, ...rest}) => {
  return (
    <Link className="mt-auto" to={href}>
    <button
      className="Button_root__xgfFx Button_l__LdRZf Button_primary__kAV1l Button_fluid__p9FP5 undefined"
      data-tnav="exchange-action-button"
      role="button"
      disabled={isDisabled}
      {...rest}
    >
      {children}
    </button>
  </Link>
  )
}

export default Button