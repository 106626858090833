import React from "react";
import Bg from "../../assets/bg.webp";
import VisaMasterCard from "../../assets/visa-mastercard.webp";
import Mercuryo from "../../assets/mercuryo.svg";
import ExchangeButton from "../ExchangeButton/ExchangeButton";
import { useSelector } from "react-redux";
import {
  selectActiveFrom,
  selectActiveTo,
  selectGet,
  selectMode,
} from "../../features/crypto/cryptoSlice";
import CategoriesComponent from "../CategoriesComponent/CategoriesComponent";
import SwapCurrencies from "../SwapCurrencies/SwapCurrencies";
import Button from "../ui/Button/Button";
import Banner from "../Banner/Banner";
import classNames from "classnames";

const ExchangeComponent = ({exchangePage}) => {
  const activeFrom = useSelector(selectActiveFrom);
  const activeTo = useSelector(selectActiveTo);
  const mode = useSelector(selectMode);
  const getVal = useSelector(selectGet)
  return (
    <section className={classNames("w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4 !px-0 sm:!max-w-[500px] border-b bg-background-secondary border-border-primary mx-auto overflow-hidden sm:border sm:border-solid sm:rounded-m", {['exchangePageblock']: exchangePage})}>
      {!window.location.href.includes('/exchange') && <Banner />}
      <div
        className="flex flex-col p-4 pb-6 lg:p-6 lg:pt-4 gap-4"
        data-tnav="calculator-body"
      >
        <div className="ToggleNavigation_root__Nh0UI">
          <div className="ToggleNavigation_body__LQKFU">
            {/* <span
              className="ToggleNavigation_selected-bg__dTH1U"
              style={{
                visibility: "visible",
                transition: "transform 0ms ease 0s",
                width: 143,
                transform: "translateX(0px)",
              }}
            /> */}
            <CategoriesComponent />
          </div>
        </div>
        {mode.id === 3 && (
          <>
            <div
              className="flex flex-col items-center py-2 sm:py-3"
              data-tnav="sell-tab-body"
            >
              <img
                alt="card"
                loading="lazy"
                width={124}
                height={88}
                decoding="async"
                data-nimg={1}
                className="h-[76px] sm:h-[88px] w-auto"
                src={VisaMasterCard}
                style={{ color: "transparent" }}
              />
              <div
                data-tnav="title"
                className="text-h5 font-semi-bold pt-5 text-center"
              >
                Sell crypto via bank card and bank transfers
              </div>
              <div className="text-p3 flex flex-col items-center gap-1 pt-2  text-content-tertiary">
                Powered by{" "}
                <img
                  alt="mercuryo"
                  loading="lazy"
                  width={93}
                  height={20}
                  decoding="async"
                  data-nimg={1}
                  src={Mercuryo}
                  style={{ color: "transparent" }}
                />
              </div>
            </div>
            <Button href="/sell">Sell crypto</Button>
          </>
        )}
        {mode.id !== 3 && (
          <div className="flex flex-col gap-4 ">
            <div className="Form_wrapper__BwS5_" data-tnav="calculator-form">
              <div className="Form_root__D7T1l">
                <ExchangeButton isFrom {...activeFrom} />
                <ExchangeButton {...activeTo} />
                {mode.id === 1 && <SwapCurrencies />}
              </div>
            </div>
            {!exchangePage && <Button isDisabled={!getVal} href={'/exchange'}>{mode.id === 1 ? "Exchange now" : "Buy Crypto"}</Button>}
          </div>
        )}
      </div>
    </section>
  );
};

export default ExchangeComponent;
