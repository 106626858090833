import React from "react";
import ExchangeImg from "../../assets/exchangeblockimg.webp";


const Banner = () => {
  return (
    <div className="relative w-full flex justify-center items-center overflow-hidden py-[26px] px-4 bg-gradient-purpleBlue hover:cursor-pointer">
      <div
        className="items-center justify-center text-p2 font-medium  absolute w-full h-full flex text-center Banner_single-animation__lvVVh"
        style={{ animationDuration: "5000ms" }}
      >
        <div className="flex items-center mx-auto gap-[10px] font-regular justify-center  sm:justify-start sm:mx-0  w-full h-full sm:pr-6">
          <img
            alt="stable coin"
            loading="lazy"
            width={98}
            height={184}
            decoding="async"
            data-nimg={1}
            className="hidden sm:block w-[50px]"
            style={{ color: "transparent" }}
            src={ExchangeImg}
          />
          <div>
            <div className="pl-6 pr-2 sm:pl-0 sm:pr-0">
              <b className="font-semi-bold">1:1 rate for stablecoin swaps:</b>
              <span className="font-normal">
                {" "}
                same&nbsp;coin, any&nbsp;chain
              </span>
            </div>
          </div>
        </div>
        {/* <div className="mr-4 ml-auto sm:mr-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M8.793 5.793a1 1 0 0 0 0 1.414L13.586 12l-4.793 4.793a1 1 0 1 0 1.414 1.414l5.5-5.5a1 1 0 0 0 0-1.414l-5.5-5.5a1 1 0 0 0-1.414 0"
              clipRule="evenodd"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
