import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import ExchangePage from "./pages/ExchangePage/ExchangePage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { calculateGet, fetchCryptoCurrencies, fetchFiatCurrencies, selectCryptoCurrencies, selectFiat, selectLoading } from "./features/crypto/cryptoSlice";
import Processing from "./components/Processing/Processing";
import SimplexPage from "./pages/SimplexPage/SimplexPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import ProcessingPage from "./pages/ProcessingPage/ProcessingPage";

function App() {
  const dispatch = useDispatch()
  const cryptopairs = useSelector(selectCryptoCurrencies)
  const fiat = useSelector(selectFiat)

  useEffect(() => {
    dispatch(fetchCryptoCurrencies())
    dispatch(fetchFiatCurrencies())
    dispatch(calculateGet())
  }, [])

  if(!cryptopairs.length || !fiat.length) return;

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/exchange" element={<ExchangePage />} />
      <Route path="/processing/:id" element={<ProcessingPage />} />
      <Route path="/simplex" element={<SimplexPage />} />
      <Route path="/admin" element={<AdminPage />} />
    </Routes>
  );
}

export default App;
