import React from "react";
import Guides1 from "../../assets/guides1.webp";
import Guides2 from "../../assets/guides2.webp";
import Guides3 from "../../assets/guides3.webp";
import Guides4 from "../../assets/guides4.webp";
import Guides5 from "../../assets/guides5.webp";
import Guides6 from "../../assets/guides6.webp";
import guides from "../../data/guides";

const Guides = () => {
  return (
    <div className="w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4 flex flex-col lg:flex-row gap-14">
      <section className="basis-1/2 lg:basis-full">
        <h3
          className="text-h3-max font-bold mb-6"
          data-tnav="helpful-guides-section-title"
        >
          Helpful guides
        </h3>
        <div className="flex flex-col gap-4 sm:gap-6">
          {guides.map((guide, index) => (
            <a
              key={index}
              className="flex rounded-l gap-4 p-2 pr-4 md:items-center -m-2 hover:bg-background-secondary hover:shadow-s"
              href={guide.link}
            >
              <img
                alt="cover"
                loading="lazy"
                width={100}
                height={100}
                decoding="async"
                data-nimg={1}
                className="size-[100px] rounded-l"
                style={{ color: "transparent" }}
                src={guide.img}
              />
              <div className="flex flex-col gap-1">
                <h4
                  className="text-h5 font-semi-bold"
                  data-tnav="helpful-guides-subtitle"
                >
                  {guide.title}
                </h4>
                <p className="text-p2 line-clamp-2">{guide.description}</p>
              </div>
            </a>
          ))}
        </div>
      </section>
      <section className="basis-1/2 lg:basis-full">
        <div>
          <h3
            className="text-h3-max font-bold mb-4"
            data-tnav="faq-section-title"
          >
            FAQ Exchange
          </h3>
          <div className="flex flex-col gap-0.5">
            <div className="w-full border-b border-border-primary border-b-solid pb-px">
              <button
                data-tnav="faq-section-question"
                aria-expanded="false"
                className="group w-full flex gap-4 items-center text-left text-p1 font-semi-bold py-2 text-content-primary"
              >
                Why should I trust Changelly?
                <div className="lg:group-hover:bg-control-overlayed-hover p-2 rounded-s ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                    className="text-content-secondary transform-gpu transition-transform rotate-180"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M18.207 15.207a1 1 0 0 1-1.414 0L12 10.414l-4.793 4.793a1 1 0 0 1-1.414-1.414l5.5-5.5a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1 0 1.414"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <div className="w-full border-b border-border-primary border-b-solid pb-px">
              <button
                data-tnav="faq-section-question"
                aria-expanded="false"
                className="group w-full flex gap-4 items-center text-left text-p1 font-semi-bold py-2 text-content-primary"
              >
                How to exchange cryptocurrencies?
                <div className="lg:group-hover:bg-control-overlayed-hover p-2 rounded-s ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                    className="text-content-secondary transform-gpu transition-transform rotate-180"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M18.207 15.207a1 1 0 0 1-1.414 0L12 10.414l-4.793 4.793a1 1 0 0 1-1.414-1.414l5.5-5.5a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1 0 1.414"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <div className="w-full border-b border-border-primary border-b-solid pb-px">
              <button
                data-tnav="faq-section-question"
                aria-expanded="false"
                className="group w-full flex gap-4 items-center text-left text-p1 font-semi-bold py-2 text-content-primary"
              >
                What cryptocurrencies do you support?
                <div className="lg:group-hover:bg-control-overlayed-hover p-2 rounded-s ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                    className="text-content-secondary transform-gpu transition-transform rotate-180"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M18.207 15.207a1 1 0 0 1-1.414 0L12 10.414l-4.793 4.793a1 1 0 0 1-1.414-1.414l5.5-5.5a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1 0 1.414"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <div className="w-full border-b border-border-primary border-b-solid pb-px">
              <button
                data-tnav="faq-section-question"
                aria-expanded="false"
                className="group w-full flex gap-4 items-center text-left text-p1 font-semi-bold py-2 text-content-primary"
              >
                How long does it take to receive my cryptocurrency?
                <div className="lg:group-hover:bg-control-overlayed-hover p-2 rounded-s ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                    className="text-content-secondary transform-gpu transition-transform rotate-180"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M18.207 15.207a1 1 0 0 1-1.414 0L12 10.414l-4.793 4.793a1 1 0 0 1-1.414-1.414l5.5-5.5a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1 0 1.414"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <div className="w-full border-b border-border-primary border-b-solid pb-px">
              <button
                data-tnav="faq-section-question"
                aria-expanded="false"
                className="group w-full flex gap-4 items-center text-left text-p1 font-semi-bold py-2 text-content-primary"
              >
                How can I contact you?
                <div className="lg:group-hover:bg-control-overlayed-hover p-2 rounded-s ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                    className="text-content-secondary transform-gpu transition-transform rotate-180"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M18.207 15.207a1 1 0 0 1-1.414 0L12 10.414l-4.793 4.793a1 1 0 0 1-1.414-1.414l5.5-5.5a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1 0 1.414"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Guides;
