export const howToSteps = [
  {
    number: 1,
    title: "Set the exchange pair",
    description: "Select the crypto pair you’d like to exchange",
  },
  {
    number: 2,
    title: "Enter your wallet address",
    description:
      "Enter your crypto wallet address to which your cryptocurrency will be sent",
  },
  {
    number: 3,
    title: "Make a payment",
    description:
      "Send the crypto you’d like to exchange to the wallet address you see on the screen",
  },
  {
    number: 4,
    title: "Wait for your coins to arrive",
    description:
      "Track the progress of your exchange and be ready to get your crypto soon",
  },
];
