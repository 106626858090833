import Exodus from "../assets/exodus.svg";
import Ledger from "../assets/ledger.svg";
import Tangem from "../assets/tangem.svg";
import CoolWallet from "../assets/coolwallet.svg";
import Ellipal from "../assets/ellipal.svg";
import Trezor from "../assets/trezor.svg";

const partners = [
  {
    alt: "exodus",
    width: 139,
    height: 56,
    src: Exodus,
  },
  {
    alt: "ledger",
    width: 108,
    height: 56,
    src: Ledger,
  },
  {
    alt: "tangem",
    width: 118,
    height: 56,
    src: Tangem,
  },
  {
    alt: "coolwallet",
    width: 151,
    height: 56,
    src: CoolWallet,
  },
  {
    alt: "ellipal",
    width: 102,
    height: 56,
    src: Ellipal,
  },
  {
    alt: "trezor",
    width: 120,
    height: 56,
    src: Trezor,
  },
];

export default partners;
