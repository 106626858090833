import React, { useState } from "react";
import styles from "./AdminItem.module.scss";
// import { ReactComponent as Edit } from "../../assets/edit.svg";
// import { ReactComponent as Delete } from "../../assets/delete.svg";
// import { ReactComponent as Success } from "../../assets/success2.svg";
import axios from "axios";

const AdminItem = ({ id, ticker, network, wallet }) => {
  const [mode, setMode] = useState(0);
  const [tickerVal, setTickerVal] = useState(ticker);
  const [networkVal, setNetworkVal] = useState(network);
  const [walletVal, setWalletVal] = useState(wallet);

  const handleDelete = async () => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/wallets/${id}`)
      .then((res) => alert("Item was deleted"))
      .catch((err) => alert("There was an error"));

    setMode(0);
  };

  const EditHandle = async () => {
    await axios
      .patch(`${process.env.REACT_APP_API_URL}/wallets/${id}`, {
        ticker: tickerVal,
        network: networkVal,
        wallet: walletVal,
      })
      .then((res) => alert("Item was updated"))
      .catch((err) => alert("There was an error"));

    setMode(0);
  };
  return (
    <div className={styles.wrap}>
      {mode === 0 ? <h4>{ticker}</h4> : ""}
      {mode === 0 ? <p>{network}</p> : ""}
      {mode === 0 ? <span style={{wordBreak: 'break-all'}}>{wallet ? wallet : "Wallet is empty"}</span> : ""}
      {mode !== 0 ? (
        <input
          className={styles.input}
          type="text"
          value={tickerVal}
          onChange={(e) => setTickerVal(e.target.value)}
        />
      ) : (
        ""
      )}
      {mode !== 0 ? (
        <input
          className={styles.input}
          type="text"
          value={networkVal}
          onChange={(e) => setNetworkVal(e.target.value)}
        />
      ) : (
        ""
      )}
      {mode !== 0 ? (
        <input
          placeholder="Wallet address"
          className={styles.input}
          type="text"
          value={walletVal}
          onChange={(e) => setWalletVal(e.target.value)}
        />
      ) : (
        ""
      )}
      <div className={styles.btns}>
        {mode === 0 ? <button onClick={() => setMode(1)}>edit</button> : ""}
        {mode !== 0 ? (
          <button onClick={() => EditHandle()}>confirm</button>
        ) : (
          ""
        )}

        {mode !== 0 ? <button onClick={() => setMode(1)}>delete</button> : ""}
        {mode === 0 ? (
          <button onClick={() => handleDelete()}>delete</button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AdminItem;
