import React from "react";
import Navbar from "../../widgets/Navbar/Navbar";
import ExchangeComponent from "../../components/ExchangeComponent/ExchangeComponent";
import Footer from "../../widgets/Footer/Footer";
import AdBlock from "../../components/AdBlock/AdBlock";
import Partners from "../../components/Partners/Partners";
import Reviews from "../../components/Reviews/Reviews";
import Subscribe from "../../components/Subscribe/Subscribe";
import AppInfo from "../../components/AppInfo/AppInfo";
import HowToExchange from "../../components/HowToExchange/HowToExchange";
import Guides from "../../components/Guides/Guides";
import BestBlock from "../../components/BestBlock/BestBlock";
import SelectList from "../../components/SelectList/SelectList";

const MainPage = () => {
  return (
    <>
      <main>
        <Navbar />
        <div className="relative flex flex-col overflow-x-hidden gap-6 sm:pt-10  lg:gap-14 pb-14 lg:pb-24">
          <ExchangeComponent />
          <AdBlock />
          <Partners />
          <Reviews />
          {/* <div className='w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-0 lg:px-4 relative !px-0' data-tnav="coin-carousel-section"></div> */}
          <Subscribe />
          <AppInfo />
          <HowToExchange />
          <Guides />
          <BestBlock />
        </div>
        <Footer />
      </main>
      {/* <SelectList /> */}
    </>
  );
};

export default MainPage;
