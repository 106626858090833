// src/data/guides.js

import Guides1 from "../assets/guides1.webp";
import Guides2 from "../assets/guides2.webp";
import Guides3 from "../assets/guides3.webp";
import Guides4 from "../assets/guides4.webp";
import Guides5 from "../assets/guides5.webp";
import Guides6 from "../assets/guides6.webp";

const guides = [
  {
    img: Guides1,
    title: "How to Exchange Crypto on Changelly: A Step-by-Step Guide",
    description: "Changelly offers a lot of various crypto services, but the core of our platform has always been our exchange.",
    link: "https://changelly.com/blog/how-to-exchange-crypto-on-changelly/"
  },
  {
    img: Guides2,
    title: "How to choose a wallet?",
    description: "In this article, Changelly will consider the types of wallets and explain which crypto wallet to choose.",
    link: "https://changelly.com/blog/how-to-choose-crypto-wallet/"
  },
  {
    img: Guides3,
    title: "Transaction ID",
    description: "So what is it, and how can it help you when making crypto and fiat transactions?",
    link: "https://changelly.com/blog/transaction-id-explained/"
  },
  {
    img: Guides4,
    title: "Cryptocurrency Hashing Algorithms Explained",
    description: "Cryptocurrency algorithms are a set of specific cryptographic mechanisms and rules that encrypt a digital currency.",
    link: "https://changelly.com/blog/hashing-algorithms-explained/"
  },
  {
    img: Guides5,
    title: "If you sent the wrong coin or to the wrong network",
    description: "Please pay close attention to the network and currency protocol displayed on our platform, when you create a transaction.",
    link: "https://changelly.com/faq/changelly/exchange-troubleshooting/#i-sent-the-wrong-coin-or-to-the-wrong-network"
  },
  {
    img: Guides6,
    title: "Investment Strategies and Tips",
    description: "Become a successful crypto investor by learning crypto investing basics and essentials.",
    link: "https://changelly.com/blog/academy/investing-strategies/"
  }
];

export default guides;
