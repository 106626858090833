import React from 'react'
import Navbar from '../../widgets/Navbar/Navbar'
import Footer from '../../widgets/Footer/Footer'
import ExchangePageComponent from '../../components/ExchangePageComponent/ExchangePageComponent'

const ExchangePage = () => {
  return (
    <>
        <Navbar />
        <ExchangePageComponent />
        <Footer />
    </>
  )
}

export default ExchangePage