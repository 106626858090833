import React, { useState } from "react";
import styles from "./AdminForm.module.scss";
import axios from "axios";

const AdminForm = () => {
  const [ticker, setTicker] = useState("");
  const [network, setNetwork] = useState("");
  const [wallet, setWallet] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();

    const walletInfo = {
      ticker,
      network,
      wallet,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/wallets`, walletInfo)
      .then((res) => {
        console.log(res);
        setTicker("");
        setNetwork("");
        setWallet("");
        alert("Coin was added");
      })
      .catch((err) => alert("There was an Error on server"));
  };
  return (
    <form onSubmit={(e) => formSubmit(e)} className={styles.form}>
      <input
        value={ticker}
        onChange={(e) => setTicker(e.target.value)}
        type="text"
        placeholder="Coin name"
      />
      <input
        value={network}
        onChange={(e) => setNetwork(e.target.value)}
        type="text"
        placeholder="Network"
      />
      <input
        value={wallet}
        onChange={(e) => setWallet(e.target.value)}
        type="text"
        placeholder="Wallet adress"
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default AdminForm;
