import React from "react";

const Subscribe = () => {
  return (
    <div className="w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4">
      <div
        data-tnav="subscribe-section"
        className="card py-6 sm:px-6 px-4 max-w-[970px] mx-auto lg:flex lg:items-center lg:gap-10"
      >
        <section className="flex gap-2 lg:items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 28 28"
            className="text-content-accent flex-shrink-0 size-7 lg:size-10"
          >
            <path
              fill="currentColor"
              d="M22.167 4.666H5.834c-1.983 0-3.5 1.517-3.5 3.5v11.667c0 1.983 1.517 3.5 3.5 3.5h16.333c1.984 0 3.5-1.517 3.5-3.5V8.166c0-1.983-1.516-3.5-3.5-3.5m0 2.333c.234 0 .35 0 .467.117l-7.817 6.883c-.466.35-1.166.35-1.516 0L5.717 7h16.45m0 14H5.834c-.7 0-1.167-.466-1.167-1.166V9.216l7 6.533c.7.584 1.517.934 2.45.934.817 0 1.634-.35 2.334-.934l7-6.183v10.267c-.117.7-.584 1.166-1.284 1.166"
            />
          </svg>
          <h3 className="text-h3 lg:text-h3-max font-bold" data-tnav="title">
            Subscribe for crypto updates
          </h3>
        </section>
        <form className="mt-6 lg:mt-0 lg:flex lg:gap-4 lg:items-center">
          <section>
            <div className="relative">
              <label
                htmlFor="email"
                className="absolute left-3 transition-all duration-200 ease-in-out text-content-secondary hover:text-content-primary text-p1 top-1/2 -translate-y-1/2"
              >
                Email
              </label>
              <input
                data-tnav="email-input"
                id="email"
                type="text"
                className="w-full pl-3 outline-none bg-control-input-default placeholder-content-secondary text-content-primary border rounded-s hover:bg-control-input-hover hover:text-content-primary hover:border-border-secondary hover:placeholder-content-primary hover:color-content-primary focus:border-border-secondary focus:bg-sexy-black-0 focus:placeholder-content-primary h-16 pb-3.5 pt-6.5 p1 border-transparent pr-3 Input_input__3O7a3 lg:w-80"
                name="email"
                defaultValue=""
              />
              <div className="h-0.5 rounded-b-s absolute bottom-0 left-1/2 -translate-x-1/2 transition-all delay-75 w-0 bg-content-primary bg-content-primary" />
              <div className="absolute right-3 top-1/2 -translate-y-1/2 flex" />
            </div>
          </section>
          <button
            className="mt-4 lg:mt-0 w-full lg:w-auto lg:!h-16 lg:!min-w-16 lg:!ps-6 lg:!pe-6 lg:!font-bold lg:!border-none Button_root__xgfFx Button_l__LdRZf Button_primary__kAV1l undefined"
            data-tnav="subscribe-button"
            role="button"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
