import React, { useEffect, useState } from "react";
import "./Processing.css";
import Scan from "../../assets/scan.svg";
import { useSelector } from "react-redux";
import {
  selectActiveFrom,
  selectActiveTo,
  selectGet,
  selectSend,
} from "../../features/crypto/cryptoSlice";
import Timer from "../Timer/Timer";
import QrForPay from "../QrForPay/QrForPay";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Processing = () => {
  const activeFrom = useSelector(selectActiveFrom);
  const activeTo = useSelector(selectActiveTo);
  const sendVal = useSelector(selectSend)
  const getVal = useSelector(selectGet)
  const [popupActive, setPopupActive] = useState(false)
  const {id} = useParams()
  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const fetchTransaction = async () => {
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/${id}`)

        setInfo(data)
        setLoading(false)
    } catch (error) {
        navigate('/')
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(info.depositAddress)
        .then(() => {
            console.log('Текст скопирован!');
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
};

  useEffect(() => {
    fetchTransaction()
  }, [])

  if(loading) return;

  const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  return (
    <main>
      <div
        className="style_content-wrapper__DmGYX"
        id="ui-content-wrapper"
        style={{ minHeight: "calc(-1147px + 100vh)" }}
      >
        <div className="style_page-wrapper__XJk8E style_top-x-small__ye7D1 style_bottom-medium__NKhSq">
          <div className="style_page-block__9_a5D style_d-center__QMto8 style_t-margin-medium__3z90w style_m-full-width__q3cIr">
            <div className="style_calc-row___c8IB">
              <div className="style_column__zDHCV style_left__dsnAQ">
                <section
                  className="style_card__KMYXA"
                  data-tnav="transaction-progress"
                >
                  <article
                    className="style_progress__E_C_f"
                    role="presentation"
                  >
                    <span>Send funds to the displayed address</span>
                    <div className="style_bottom-container__del7s">
                      <div className="style_progress-bar__A3SQO">
                        <div className="style_progress-bar__n4fXy">
                          <div
                            className="style_progress-line__sdnwl"
                            style={{ width: "66%" }}
                          />
                        </div>
                      </div>
                      <div className="style_title-container___k50W">
                        <h5 className="style_title__3xOBu">Payment</h5>
                        <span className="style_iconWrp__3jZAj style_open__lVIxC">
                          <svg
                            width={8}
                            height={5}
                            viewBox="0 0 8 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.24992 0.335862C1.64045 -0.0546623 2.27361 -0.0546623 2.66414 0.335862L4.45703 2.12876L6.24992 0.335862C6.64045 -0.0546621 7.27361 -0.0546621 7.66414 0.335862C8.05466 0.726387 8.05466 1.35955 7.66414 1.75008L4.45703 4.95718L1.24992 1.75008C0.8594 1.35955 0.8594 0.726386 1.24992 0.335862Z"
                              fill="#10D35E"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="style_label__k5eru">
                        <span>3 OF 4</span>
                      </div>
                    </div>
                  </article>
                  <article
                    className="style_step__2jmza"
                    data-tnav="exchange-pair-step"
                  >
                    <span className="style_check-mark-icon__ZJFYP">
                      <svg
                        width="12px"
                        height="10px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 426.67 426.67"
                      >
                        <path
                          d="M153.504 366.839c-8.657 0-17.323-3.302-23.927-9.911L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.386c13.218-13.214 34.65-13.218 47.859 0 13.222 13.218 13.222 34.65 0 47.863L177.436 356.928c-6.609 6.605-15.271 9.911-23.932 9.911z"
                          fill="#10D35E"
                        />
                      </svg>
                    </span>
                    <div className="style_text-container__Rl2cN style_done__XmkRU">
                      <h5 className="style_title__3xOBu">Exchange pair</h5>
                      <span>Set the preferred exchange pair</span>
                    </div>
                  </article>
                  <article
                    className="style_step__2jmza"
                    data-tnav="wallet-address-step"
                  >
                    <span className="style_check-mark-icon__ZJFYP">
                      <svg
                        width="12px"
                        height="10px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 426.67 426.67"
                      >
                        <path
                          d="M153.504 366.839c-8.657 0-17.323-3.302-23.927-9.911L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.386c13.218-13.214 34.65-13.218 47.859 0 13.222 13.218 13.222 34.65 0 47.863L177.436 356.928c-6.609 6.605-15.271 9.911-23.932 9.911z"
                          fill="#10D35E"
                        />
                      </svg>
                    </span>
                    <div className="style_text-container__Rl2cN style_done__XmkRU">
                      <h5 className="style_title__3xOBu">Wallet address</h5>
                      <span>Fill in the crypto wallet address details</span>
                    </div>
                  </article>
                  <article
                    className="style_step__2jmza"
                    data-tnav="payment-step"
                  >
                    <div className="style_arrow-right-icon__b_MTv">
                      <svg
                        width="10px"
                        height="10px"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.46663 11.1334L11.1333 6.46675C11.2 6.40008 11.2666 6.33341 11.2666 6.26675C11.3333 6.13341 11.3333 5.93341 11.2666 5.73341C11.2 5.66675 11.2 5.60008 11.1333 5.53341L6.46663 0.866748C6.19996 0.600081 5.79996 0.600081 5.53329 0.866748C5.26663 1.13341 5.26663 1.53341 5.53329 1.80008L9.06663 5.33341H1.33329C0.933293 5.33341 0.666626 5.60008 0.666626 6.00008C0.666626 6.40008 0.933293 6.66675 1.33329 6.66675H9.06663L5.53329 10.2001C5.39996 10.3334 5.33329 10.4667 5.33329 10.6667C5.33329 10.8667 5.39996 11.0001 5.53329 11.1334C5.79996 11.4001 6.19996 11.4001 6.46663 11.1334Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="style_text-container__Rl2cN">
                      <h5 className="style_title__3xOBu">Payment</h5>
                      <span>Deposit the amount required for the exchange</span>
                    </div>
                  </article>
                  <article
                    className="style_step__2jmza"
                    data-tnav="exchange-step"
                  >
                    <div className="style_number__a_TWo">4</div>
                    <div className="style_text-container__Rl2cN">
                      <h5 className="style_title__3xOBu">Exchange</h5>
                      <span>Wait for your transaction to be completed</span>
                    </div>
                  </article>
                </section>
              </div>
              <div className="style_column__zDHCV style_centred__41Rno">
                <section className="style_card__KMYXA">
                  <div className="style_block__32eCO">
                    <h2
                      className="cl-heading style_heading__mFYLj style_no-gap__RL9er style_color-black__NheV8 style_size-md__IN3MI"
                      style={{ zoom: 1, textAlign: "left", marginBottom: 0 }}
                    >
                      Send funds to the address below
                    </h2>
                  </div>
                  <div className="style_block__32eCO">
                    <div className="">
                      <div className="style_details-card-item__OzEP9">
                        <div className="style_label-block__JkEX_">
                          <span className="style_label__8r8k0">Amount</span>
                        </div>
                        <div className="style_value-block__PwUh9">
                          <div className="style_flex-block__U00qb">
                            <span
                              className="style_value__Y6DJu"
                              data-tnav="amount"
                            >
                              {info.send} {" "} {info.from.cmcTicker.toUpperCase()}
                            </span>
                            <span className="" data-role="tooltip-action-area">
                              <svg
                                className="style_pencil-icon__MVKea"
                                width={14}
                                height={15}
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                data-tnav="edit-pencil-svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10 0C10.5288 0 10.9869 0.209494 11.3544 0.579913L13.4217 2.64718C13.7924 3.01786 14 3.47274 14 4C14 4.52385 13.794 5.06728 13.4217 5.43959L5.89021 12.9675C5.36661 13.5715 4.62438 13.9426 3.7564 14.0016H0V13.0016L0.00324765 10.1647C0.0669805 9.37582 0.43373 8.64229 0.981963 8.15991L8.56093 0.581008C8.93285 0.207181 9.47538 0 10 0ZM2.35157 9.61608C2.14601 9.79788 2.01885 10.0522 2 10.2452V12.0025L3.68578 12.004C3.95369 11.9851 4.20307 11.8604 4.42749 11.6054L8.80933 7.22354L6.77751 5.19172L2.35157 9.61608ZM8.19197 3.77776L10.2235 5.80933L11.9895 4.04339L9.95824 2.01212L8.19197 3.77776Z"
                                  fill=""
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="style_details-card-item__OzEP9">
                        <div className="style_label-block__JkEX_">
                          <span className="style_label__8r8k0">
                            Changelly address ({info.from.cmcTicker.toUpperCase()})
                          </span>
                        </div>
                        <div className="style_value-block__PwUh9">
                          <span
                            data-tnav="changelly-address"
                            className="style_value__Y6DJu"
                          >
                            {info.depositAddress}
                          </span>
                          <span className="style_blockchain-type__RP94b">
                            blockchain: {info.from.network ? info.from.network : info.from.symbol}
                          </span>
                          <div className="style_address-buttons-block__Njv2X">
                            <button
                            onClick={handleCopy}
                              className="style_button__bduhc style_copy-address-button__IR_VT  style_size-medium__rw8R3 style_color-green__0zg4u style_full-width__jogCF"
                              type="button"
                              data-tnav="copy-address-button"
                            >
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7 11H9C10.1 11 11 10.1 11 9V7H13V13H7V11ZM3 9H9V3H3V9ZM3 1C1.9 1 1 1.9 1 3V9C1 10.1 1.9 11 3 11H5V13C5 14.1 5.9 15 7 15H13C14.1 15 15 14.1 15 13V7C15 5.9 14.1 5 13 5H11V3C11 1.9 10.1 1 9 1H3Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Copy address
                            </button>
                            <button
                            onClick={() => setPopupActive(true)}
                              className="style_button__bduhc style_show-qr-code-button__Y2Uth  style_size-medium__rw8R3 style_color-blue__BJge7"
                              type="button"
                              data-tnav="qr-open-button"
                            >
                              <img src={Scan} alt="QR Code" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <section
                        className="style_alert-message__KAbBw style_warn__mn3oV style_small__EN_Z_"
                        style={{ marginBottom: 0 }}
                      >
                        <i className="style_alert-message-icon__dBWQj style_warn__mn3oV">
                          <i
                            className=""
                            style={{ display: "flex", verticalAlign: "middle" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={4}
                              height={9}
                              viewBox="0 0 4 11"
                            >
                              <path
                                fill="white"
                                fillRule="nonzero"
                                transform="rotate(0)"
                                d="M0.921027 1.80957C0.887525 1.18282 1.3867 0.65625 2.01434 0.65625C2.64295 0.65625 3.14247 1.18436 3.10753 1.812L2.8242 6.90049C2.80011 7.33328 2.44214 7.67188 2.00868 7.67188C1.57452 7.67188 1.21622 7.33222 1.19305 6.89868L0.921027 1.80957ZM2.01248 10.7656C1.72082 10.7656 1.47915 10.6689 1.28748 10.4753C1.09582 10.2818 0.999985 10.043 0.999985 9.75917C0.999985 9.4753 1.09582 9.23874 1.28748 9.0495C1.47915 8.86025 1.72082 8.76562 2.01248 8.76562C2.29582 8.76562 2.53123 8.86025 2.71873 9.0495C2.90624 9.23874 2.99998 9.4753 2.99998 9.75917C2.99998 10.043 2.90624 10.2818 2.71873 10.4753C2.53123 10.6689 2.29582 10.7656 2.01248 10.7656Z"
                              />
                            </svg>
                          </i>
                        </i>
                        <span className="style_alert-message-text__PM9lp">
                          Please note that you can send funds to the address
                          above only once.
                        </span>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div className="style_column__zDHCV style_right__XmIgP">
                <section className="style_deadline-timer-wrapper__PVCox style_card__KMYXA">
                  <div className="style_transaction-block-with-detail__Hoz0n">
                    <div className="style_time__Yf_Mc" data-tnav="tx-timer">
                      <div className="style_info__gpu6y" data-tnav="timer">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 58 58"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M29 54C15.2193 54 4 42.7807 4 29C4 15.2193 15.2193 4 29 4C42.7807 4 54 15.2193 54 29C54 42.7807 42.7807 54 29 54ZM29 58C13.0101 58 0 44.9899 0 29C0 13.0101 13.0101 0 29 0C44.9899 0 58 13.0101 58 29C58 44.9899 44.9899 58 29 58ZM31 17C31 15.8954 30.1046 15 29 15C27.8954 15 27 15.8954 27 17V28C27 29.6569 28.3431 31 30 31H41C42.1046 31 43 30.1046 43 29C43 27.8954 42.1046 27 41 27H31V17Z"
                            fill="#b4b4b4"
                          />
                        </svg>
                        <Timer createdAt={info.createdAt} />
                      </div>
                      <p className="cl-para style_wrapper-paragraph__ptRNI style_description__4mAiL">
                        Time left to send {info.send} {" "} {info.from.cmcTicker.toUpperCase()}
                      </p>
                    </div>
                  </div>
                  <div className="style_line__ZoEHF" />
                  <div className="style_transaction-block-with-detail__Hoz0n">
                    <div className="style_time__Yf_Mc" data-tnav="tx-timer">
                      <div className="style_info__gpu6y">
                        <div className="TransactionDetails_replicable-item__rS3hI">
                          <div
                            className="TransactionDetails_heading__R_ZkC"
                            data-tnav="transaction-id"
                          >
                            {generateRandomString(16)}
                          </div>
                          <button
                            className="TransactionDetails_copy-button__alfiV"
                            type="button"
                            data-tnav="copy-button"
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7 11H9C10.1 11 11 10.1 11 9V7H13V13H7V11ZM3 9H9V3H3V9ZM3 1C1.9 1 1 1.9 1 3V9C1 10.1 1.9 11 3 11H5V13C5 14.1 5.9 15 7 15H13C14.1 15 15 14.1 15 13V7C15 5.9 14.1 5 13 5H11V3C11 1.9 10.1 1 9 1H3Z"
                                fill="#B4B4B4"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <p className="cl-para style_wrapper-paragraph__ptRNI style_description__4mAiL">
                        Transaction ID
                      </p>
                    </div>
                  </div>
                </section>
                <section className="style_card__KMYXA" data-tnav="tx-details">
                  <h3
                    className="cl-heading style_heading__mFYLj style_no-gap__RL9er style_color-gray__ys0OB style_size-sm__7zd3F"
                    style={{ zoom: 1, textAlign: "left", marginBottom: 0 }}
                  >
                    Transaction details
                  </h3>
                  <div className="TransactionDetails_details__06_tU TransactionDetails_with-result__MgjXS TransactionDetails_with-title__N2eUi">
                    <div>
                      <p
                        className="TransactionDetails_caption__wDuPi"
                        data-tnav="tx-details-sending-caption"
                      >
                        You send
                      </p>
                      <p
                        className="TransactionDetails_heading__R_ZkC"
                        data-tnav="tx-details-sending-value"
                      >
                        {info.send} {" "} {info.from.cmcTicker.toUpperCase()}
                      </p>
                    </div>
                    {/* <div>
                      <p
                        className="TransactionDetails_caption__wDuPi"
                        data-tnav="tx-details-exchange-rate-caption"
                      >
                        Exchange rate
                      </p>
                      <p
                        className="TransactionDetails_heading__R_ZkC"
                        data-tnav="tx-details-exchange-rate-value"
                      >
                        1 ETH ~ 0.04785604 BTC
                      </p>
                    </div> */}
                    <div>
                      <p
                        className="TransactionDetails_caption__wDuPi"
                        data-tnav="tx-details-service-fee-caption"
                      >
                        Service fee 0.25%
                      </p>
                      <p
                        className="TransactionDetails_heading__R_ZkC"
                        data-tnav="tx-details-service-fee-value"
                      >
                        {Number(info.send) * 0.25 / 100} {" "} {info.from.cmcTicker.toUpperCase()}
                      </p>
                    </div>
                    {/* <div>
                      <p
                        className="TransactionDetails_caption__wDuPi"
                        data-tnav="tx-details-cost-caption"
                      >
                        Transaction cost
                      </p>
                      <p
                        className="TransactionDetails_heading__R_ZkC"
                        data-tnav="tx-details-cost-value"
                      >
                        0.00004595 BTC
                      </p>
                    </div> */}
                    <div>
                      <p
                        className="TransactionDetails_caption__wDuPi"
                        data-tnav="tx-details-getting-caption"
                      >
                        You get
                      </p>
                      <p
                        className="TransactionDetails_heading__R_ZkC"
                        data-tnav="tx-details-getting-value"
                      >
                        ~ {info.getVal} {info.to.cmcTicker.toUpperCase()}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupActive && <QrForPay value={info.depositAddress} setActive={setPopupActive} />}
    </main>
  );
};

export default Processing;
