import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Timer = ({ createdAt }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate()

  useEffect(() => {
    const endTime = new Date(createdAt).getTime() + 3 * 60 * 60 * 1000;
    const currentTime = Date.now();
    const initialTimeLeft = endTime - currentTime;

    if (initialTimeLeft > 0) {
      setTimeLeft(initialTimeLeft);
    } else {
      // If the time has already passed, redirect immediately
    //   redirectToAnotherPage();
    }

    const interval = setInterval(() => {
      const now = Date.now();
      const remainingTime = endTime - now;
      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
      } else {
        clearInterval(interval);
        navigate('/')
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [createdAt]);

//   const redirectToAnotherPage = () => {
//     history.push('/another-page'); // Change this to your target page
//   };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
        {formatTime(timeLeft)}
    </>
  );
};

export default Timer;
