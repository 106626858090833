import React from "react";
import { useDispatch } from "react-redux";

const CurrencyComponent = ({ ticker, name, imageUrl, network }) => {

  return (
    <div
      title={ticker}
      className="CurrencyList_list-item__TvNjb flex items-center justify-between px-4"
      data-tnav="currency-list-item"
    >
      <div className="Currency_item__sXrMy flex items-center">
        <img
          src={imageUrl}
          alt={`${ticker} logo`}
          className="Currency_ticker-img__2oG16"
        />
        <div>
          <div className="flex items-center text-p1 font-semi-bold">
            <span data-tnav="currency-stretch-ticker">
              {ticker.toUpperCase()}
            </span>
            <span
              data-tnav="currency-stretch-network"
              className="ml-1 Tag_theme__dAV3O Tag_navy__YG6dX inline-flex text-nowrap text-p3 font-semi-bold px-1 rounded-xs"
            >
              {network ? network.toUpperCase() : ""}
              {/* network */}
            </span>
          </div>
          <p className="text-p3" data-tnav="currency-stretch-fullname">
            {name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrencyComponent;
