import React, { useState } from "react";
import { categories } from "../../data/categories";
import styles from "./CategoriesComponent.module.scss";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { calculateGet, fetchFiatCurrencies, selectMode, setMode, setSend } from "../../features/crypto/cryptoSlice";

const CategoriesComponent = () => {
  const mode = useSelector(selectMode);
  const dispatch = useDispatch();

  const handleCategoryChange = (cat) => {
    dispatch(setMode(cat));
    dispatch(calculateGet())
    // dispatch(setSend("0.1"))
    // if(cat.id === 2) {
    //     dispatch(fetchFiatCurrencies())
    // }
  };

  return (
    <>
      {categories.map((cat) => (
        <button
          key={cat.id}
          className={classNames(
            "ToggleNavigation_item__DFnMx ToggleNavigation_selected__oNGQ4 text-p2 font-semi-bold text-nowrap text-ellipsis overflow-hidden",
            styles.btn,
            { [styles.active]: mode.id === cat.id }
          )}
          disabled={cat.id === 4 || cat.id === 3}
          data-tnav="navigation-item-exchange"
          onClick={() => handleCategoryChange(cat)}
        >
          {cat.title}
        </button>
      ))}
    </>
  );
};

export default CategoriesComponent;
