import React from 'react'
import Play from "../../assets/play.svg"
import Android from "../../assets/apk.svg"
import Qr from "../../assets/qr.svg"
import Devices from "../../assets/devices.webp"
import Bg from "../../assets/bg.webp"

const AppInfo = () => {
  return (
<section className="relative" id="mobile-section">
  <img
    alt="gradient background"
    loading="lazy"
    decoding="async"
    data-nimg="fill"
    className="absolute w-full h-auto"
    style={{
      position: "absolute",
      height: "100%",
      width: "100%",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      color: "transparent"
    }}
    sizes="100vw"
    src={Bg}
  />
  <div
    className="w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4 relative sm:grid sm:grid-cols-2 sm:gap-8 my-4 lg:my-0 lg:!max-w-[968px] lg:px-5"
    data-tnav="section-mobile"
  >
    <div>
      <h2
        className="text-h2 sm:text-h3 lg:text-h2-max font-bold text-center sm:text-left"
        data-tnav="mobile-section-title"
      >
        Exclusive benefits
        <br /> of the Changelly app
      </h2>
      <p
        className="text-p2 sm:text-p1 mt-4"
        data-tnav="mobile-section-description"
      >
        Get <b className="font-semi-bold">better rates</b> in the app and enjoy
        an <b className="font-semi-bold">exclusive discount</b> on your very
        first crypto exchange transaction. Don’t settle for less when you can
        maximize your returns with us!
      </p>
      <p className="mt-3 text-p3 text-content-secondary">
        This offer does not apply for users based in UK
      </p>
      <div className="flex gap-4 items-center my-8 sm:mt-4 lg:mt-10">
        <img
          alt="mobile-app-qr"
          loading="lazy"
          width={136}
          height={136}
          decoding="async"
          data-nimg={1}
          className="hidden lg:block"
          style={{ color: "transparent" }}
          src={Qr}
        />
        <div className="flex flex-col gap-2 w-full sm:w-auto grid grid-cols-1 sm:grid-cols-2 gap-2 lg:grid-cols-1">
          <a
            href="https://changelly.page.link/?link=https%3A%2F%2Fchangelly.com%2F%3Futm_medium%3Dweb%26utm_source%3Dchangelly_main%26utm_campaign%3Dlanding_mobileapp%26web_device_id%3D9aa6d53f-ff23-444e-8273-9e226651733e%26app_offer_id%3Dapp_promo_banners&apn=com.changelly.app&ibi=com.changelly.iosapp&isi=1435140380&ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.changelly.app&utm_source=changelly_main&efr=1"
            target="_blank"
            className="flex place-content-center w-full h-10 rounded-s text-static-white bg-static-dark border-solid border border-border-primary hover:border-static-brand"
            data-tnav="android-app-button"
            rel="noopener noreferrer nofollow"
          >
            <img
              alt="googlePlay"
              loading="lazy"
              width={128}
              height={40}
              decoding="async"
              data-nimg={1}
              src={Play}
              style={{ color: "transparent" }}
            />
          </a>
          <a
            href="https://changelly.page.link/?link=https%3A%2F%2Fchangelly.com%2F%3Futm_medium%3Dweb%26utm_source%3Dchangelly_main%26utm_campaign%3Dlanding_mobileapp%26web_device_id%3D9aa6d53f-ff23-444e-8273-9e226651733e%26app_offer_id%3Dapp_promo_banners&apn=com.changelly.app&ibi=com.changelly.iosapp&isi=1435140380&afl=https%3A%2F%2Fdrive.google.com%2Fdrive%2Ffolders%2F1TeEeY-34J0HeRfDh0k5-N5NtakfNDpbG&ofl=https%3A%2F%2Fdrive.google.com%2Fdrive%2Ffolders%2F1TeEeY-34J0HeRfDh0k5-N5NtakfNDpbG&efr=1"
            target="_blank"
            className="flex place-content-center w-full h-10 rounded-s text-static-white bg-static-dark border-solid border border-border-primary hover:border-static-brand"
            data-tnav="apk-app-button"
            rel="noopener noreferrer nofollow"
          >
            <img
              alt="androidApk"
              loading="lazy"
              width={128}
              height={40}
              decoding="async"
              data-nimg={1}
              src={Android}
              style={{ color: "transparent" }}
            />
          </a>
        </div>
      </div>
    </div>
    <img
      alt="devices"
      loading="lazy"
      width={738}
      height={896}
      decoding="async"
      data-nimg={1}
      className="my-0 mx-auto w-56 sm:w-[260px] lg:w-[368px]"
      style={{ color: "transparent" }}
      sizes="(max-width: 384px) 200px, (max-width: 1023px) 260px, 368px"
        src={Devices}
    />
  </div>
  <div className="w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4 relative mt-6 lg:mt-14 !px-0">
    <div className="hide-scrollbar overflow-x-auto flex gap-2 lg:gap-4 px-4 xl:px-0">
      <div className="card py-6 lg:py-8 px-4 lg:px-6 w-[94%] sm:w-[47%] lg:w-[33.3%] flex-none">
        <h3 className="text-p2 sm:text-p1 text-content-secondary flex mb-2 flex-col items-start gap-1">
          Javier L., AppStore
          <div className="flex items-center gap-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
          </div>
        </h3>
        <p className="text-p2 sm:text-p1 line-clamp-5 line-clamp-4">
          I am extremely delighted with the exceptional service provided by the
          Changelly crypto swap app.
        </p>
      </div>
      <div className="card py-6 lg:py-8 px-4 lg:px-6 w-[94%] sm:w-[47%] lg:w-[33.3%] flex-none">
        <h3 className="text-p2 sm:text-p1 text-content-secondary flex mb-2 flex-col items-start gap-1">
          Chang Su, AppStore
          <div className="flex items-center gap-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
          </div>
        </h3>
        <p className="text-p2 sm:text-p1 line-clamp-5 line-clamp-4">
          Thank you. Love this! Good to take a little profits here and there.
        </p>
      </div>
      <div className="card py-6 lg:py-8 px-4 lg:px-6 w-[94%] sm:w-[47%] lg:w-[33.3%] flex-none">
        <h3 className="text-p2 sm:text-p1 text-content-secondary flex mb-2 flex-col items-start gap-1">
          Liam D., Google Play
          <div className="flex items-center gap-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={13}
              fill="none"
              viewBox="0 0 14 13"
            >
              <path
                fill="#FFB000"
                d="M6.524.464a.5.5 0 0 1 .952 0l1.208 3.718a.5.5 0 0 0 .475.346h3.91a.5.5 0 0 1 .294.904L10.2 7.731a.5.5 0 0 0-.182.559l1.209 3.719a.5.5 0 0 1-.77.559l-3.163-2.299a.5.5 0 0 0-.588 0l-3.163 2.299a.5.5 0 0 1-.77-.56L3.982 8.29a.5.5 0 0 0-.182-.56L.636 5.433a.5.5 0 0 1 .294-.904h3.91a.5.5 0 0 0 .476-.346z"
              />
            </svg>
          </div>
        </h3>
        <p className="text-p2 sm:text-p1 line-clamp-5 line-clamp-4">
          very fast, very easy and super rates! definitely recommend!
        </p>
      </div>
    </div>
  </div>
</section>

  )
}

export default AppInfo