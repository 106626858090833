import React, { useEffect, useState } from "react";
import styles from "./AdminPage.module.scss";
import classNames from "classnames";
import axios from "axios";
import Auth from "../../components/Auth/Auth";
import AdminForm from "../../components/AdminForm/AdminForm";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import AdminList from "../../components/AdminList/AdminList";

const AdminPage = () => {
  const [search, setSearch] = useState("");
  const [login, setLogin] = useState(false);
  const [items, setItems] = useState([]);

  const updateItems = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/wallets`
    );
    console.log(data);
    setItems(data);
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/wallets`
      );
      console.log(data);
      setItems(data);
    };

    getData();
    if (typeof window !== "undefined") {
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");

      if (username && password) {
        setLogin(true);
      }
    }
  }, []);
  return (
    <section className={styles.admin}>
      <div className={classNames("container", styles.container)}>
        {!login ? <Auth login={login} fnc={setLogin} /> : ""}
        {login ? (
          <>
            <div className={styles.d}>
              <AdminForm />
              <AdminSearch search={search} fnc={setSearch} />
            </div>
            <AdminList items={items} func={updateItems} search={search} />
          </>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default AdminPage;
