import React, { useState } from "react";
import styles from "./Auth.module.scss";
import axios from "axios";

const Auth = ({ login, fnc }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth`, {
        username: email,
        password,
      })
      .then((res) => {
        if (res.data.success) {
          fnc(true);
          localStorage.setItem("username", email);
          localStorage.setItem("password", password);
        } else {
          fnc(false);
        }
      });
  };
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h1>Login</h1>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Username"
        type="text"
      />
      <input
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        type="password"
      />
      <button type="submit">Login</button>
    </form>
  );
};

export default Auth;
