import React, { useState, useEffect } from "react";

const TextAnimation = () => {
  const words = ["securely", "easily", "instantly"];
  const [index, setIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (subIndex === words[index].length + 1 && !reverse) {
      setTimeout(() => setReverse(true), 1000);
      return;
    }
    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
      return;
    }

    const timeout = setTimeout(() => {
      setDisplayedText(words[index].substring(0, subIndex));
      setSubIndex((prevSubIndex) => prevSubIndex + (reverse ? -1 : 1));
    }, 100);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse, words]);

  return (
    <span className="h-8 sm:h-[44px] lg:h-[52px] gradient-webkit bg-gradient-greenToBlue bg-clip-text inline text-static-white">
      {" "}
      {displayedText}
    </span>
  );
};

export default TextAnimation;
