// src/features/crypto/cryptoSlice.js
import { createSlice } from "@reduxjs/toolkit";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { categories } from "../../data/categories";
import { API } from "../../Api/axios";

export const fetchCryptoCurrencies = createAsyncThunk(
  "crypto/fetchCryptoCurrencies",
  async (_, { rejectWithValue, getState }) => {
    const { offset } = getState().crypto;
    try {
      const { data } = await API.get(
        `/currencies/list?includeDisabled=false&extended=false&limit=30&isFiat=false&isFixed=false&offset=${offset}`
      );
      const { result, count } = data;

      const currenciesToSet = result.map((cur) => {
        return {
          ...cur,
          img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
        };
      });

      return { currencies: currenciesToSet, count };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const calculateGet = createAsyncThunk(
  "crypto/calculateGet",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const { data } = await API.get(
      `/estimates?currencyFrom=${state.crypto.activeFrom.symbol}&currencyTo=${state.crypto.activeTo.symbol}&amount=${state.crypto.send}&fixed=false&site=true`
    );
    return data.result;
  }
);

export const searchCurrencies = createAsyncThunk(
  "crypto/searchCurrencies",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();

    const { data } = await API.get(
      `/currencies/list?includeDisabled=false&extended=false&limit=30&isFiat=false&isFixed=false&offset=0&search=${state.crypto.search}`
    );

    const currenciesToSet = data.result.map((cur) => {
      return {
        ...cur,
        img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
      };
    });

    return currenciesToSet;
  }
);

export const searchFiatCurrencies = createAsyncThunk(
  "crypto/searchFiatCurrencies",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();

    const { data } = await API.get(
      `/currencies/list?includeDisabled=false&extended=false&limit=42&isFiat=true&isFixed=false&offset=0&search=${state.crypto.search}`
    );

    const currenciesToSet = data.result.map((cur) => {
      return {
        ...cur,
        img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
      };
    });

    return currenciesToSet;
  }
);

export const fetchFiatCurrencies = createAsyncThunk(
  "crypto/fetchFiatCurrencies",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const { data } = await API.get(
      `/currencies/list?includeDisabled=false&extended=false&limit=42&isFiat=true&isFixed=false&offset=0&search=`
    );

    const currenciesToSet = data.result.map((cur) => {
        return {
          ...cur,
          img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
        };
      });

    return {result: currenciesToSet, count: data.count, offset: data.offset};
  }
);

const initialState = {
  crypto: [],
  activeFrom: {
    isFavorite: false,
    dailyChange: "0.16451973",
    symbol: "btc",
    cmcTicker: "btc",
    name: "Bitcoin",
    network: null,
    symbolFront: "btc",
    validationAddress:
      "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
    validationExtra: null,
    extraId: null,
    hasExtraId: false,
    isFiat: false,
    priority: true,
    priorityFrom: 1250,
    priorityTo: 1137,
    warningsFrom: [],
    warningsTo: [],
    img: "https://static.simpleswap.io/images/currencies-logo/btc.svg",
  },
  activeTo: {
    isFavorite: false,
    dailyChange: "-0.87979743",
    symbol: "eth",
    cmcTicker: "eth",
    name: "Ethereum",
    network: null,
    symbolFront: "eth",
    validationAddress: "^(0x)[0-9A-Fa-f]{40}$",
    validationExtra: null,
    extraId: null,
    hasExtraId: false,
    isFiat: false,
    priority: true,
    priorityFrom: 1249,
    priorityTo: 1139,
    warningsFrom: [],
    warningsTo: [],
    img: "https://static.simpleswap.io/images/currencies-logo/eth.svg",
  },
  fiat: [],
  loading: false,
  error: false,
  offset: 0,
  count: null,
  search: "",
  searchResults: [],
  send: "0.1",
  get: null,
  min: null,
  max: null,
  mode: categories[0],
};

const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    setCryptoCurrencies(state, action) {
      state.crypto = action.payload;
    },
    setOffset(state, action) {
      state.offset = action.payload;
    },
    setActiveFrom(state, action) {
      state.activeFrom = action.payload;
    },
    setActiveTo(state, action) {
      state.activeTo = action.payload;
    },
    setSend(state, action) {
      state.send = action.payload;
    },
    setGet(state, action) {
      state.send = action.payload;
    },
    setSearchVal(state, action) {
      state.search = action.payload;
    },
    setSwap(state, action) {
      const from = state.activeFrom;
      const to = state.activeTo;

      state.activeFrom = to;
      state.activeTo = from;
    },
    setMode(state, action) {
      state.mode = action.payload;
      state.offset = 0;
      state.search = ''

      if(state.mode.id === 1) {
        state.activeFrom = state.crypto[0]
        state.activeTo = state.crypto[1]
      } else if(state.mode.id === 2) {
        state.activeFrom = state.fiat[0]
        state.activeTo = state.crypto[0]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoCurrencies.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCryptoCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.crypto = [...state.crypto, ...action.payload.currencies];
        // state.activeFrom = action.payload.currencies[0];
        // state.activeTo = action.payload.currencies[1];
        state.count = action.payload.count;
      })
      .addCase(fetchCryptoCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFiatCurrencies.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchFiatCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.fiat = action.payload.result;
        // state.activeFrom = action.payload.result[0];
        // state.activeTo = action.payload.currencies[1];
        state.count = action.payload.count;
      })
      .addCase(fetchFiatCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchFiatCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchFiatCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchFiatCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(calculateGet.pending, (state) => {
        state.loading = true;
      })
      .addCase(calculateGet.fulfilled, (state, action) => {
        state.loading = false;
        state.get = action.payload.estimate;
        state.min = action.payload.min;
        state.max = action.payload.max;
      })
      .addCase(calculateGet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.min = null;
        state.max = null;
        state.get = "";
      });
  },
});

export const {
  setCryptoCurrencies,
  setOffset,
  setActiveFrom,
  setActiveTo,
  setSend,
  setSearchVal,
  setSwap,
  setMode,
} = cryptoSlice.actions;

export const selectActiveFrom = (state) => state.crypto.activeFrom;
export const selectActiveTo = (state) => state.crypto.activeTo;
export const selectCryptoCurrencies = (state) => state.crypto.crypto;
export const selectOffset = (state) => state.crypto.offset;
export const selectLoading = (state) => state.crypto.loading;
export const selectSend = (state) => state.crypto.send;
export const selectGet = (state) => state.crypto.get;
export const selecteSearched = (state) => state.crypto.searchResults;
export const selectMin = (state) => state.crypto.min;
export const selectMode = (state) => state.crypto.mode;
export const selectFiat = (state) => state.crypto.fiat;

export default cryptoSlice.reducer;
