export const categories = [
    {
        title: 'Exchange',
        id: 1,
        mode: 'c2c'
    },
    {
        title: 'Buy',
        id: 2,
        mode: 'f2c'
    },
    {
        title: 'Sell',
        id: 3,
        mode: 'c2f'
    },
    {
        title: 'DeFi',
        id: 4,
        mode: 'c2c'
    },
]