import React from "react";
import partners from "../../data/partners";

const Partners = () => {
  return (
    <div className="w-full lg:mx-auto lg:max-w-[var(--maxContentWidth)] px-4">
      <div className="hide-scrollbar overflow-x-auto -mr-4 -ml-4 px-4">
        <div className="flex gap-6 w-full sm:max-w-[440px] sm:flex-wrap sm:justify-center sm:mx-auto lg:px-10 lg:max-w-full lg:justify-between">
          {partners.map((partner, index) => (
            <img
              key={index}
              alt={partner.alt}
              loading="lazy"
              width={partner.width}
              height={partner.height}
              decoding="async"
              data-nimg={1}
              className="h-12 last:pr-4 w-auto relative shrink-0 sm:last:p-0 md:h-14 object-contain"
              style={{ color: "transparent" }}
              src={partner.src}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
