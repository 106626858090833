import React from 'react'
import Navbar from '../../widgets/Navbar/Navbar'
import Footer from '../../widgets/Footer/Footer'
import Processing from '../../components/Processing/Processing'

const ProcessingPage = () => {
  return (
    <>
        <Navbar />
        <Processing />
        <Footer />
    </>
  )
}

export default ProcessingPage